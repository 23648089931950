<template>
  <Home>
    <PropTradingDashboardTopBanner v-if="showPropTrade" />
    <div class="table-list-box">
      <h3>{{ $t('menu.homeDemo') }} ＞＞＞</h3>
      <div class="table-item-box">
        <div class="table-box">
          <el-table :data="allMetaDemoAccounts">
            <el-table-column
              show-overflow-tooltip
              :label="$t('common.column.accNum')"
              prop="accNum"
              min-width="130"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              :label="$t('common.column.server')"
              prop="server"
              min-width="180"
            ></el-table-column>
            <el-table-column show-overflow-tooltip :label="$t('common.column.type')" min-width="180">
              <template slot-scope="scope">
                {{ $config.accountTypeMaps[scope.row.accMt4Type] }}
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip :label="$t('common.column.PLATFORM')" min-width="150">
              <template slot-scope="scope">
                {{ 'MT' + scope.row.platform }}
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              :label="$t('common.column.currency')"
              prop="currency"
              min-width="120"
            ></el-table-column>
            <el-table-column show-overflow-tooltip :label="$t('common.column.equity')" min-width="120">
              <template slot-scope="scope">{{ scope.row.equity | formatNumber }}</template>
            </el-table-column>
            <!-- <el-table-column show-overflow-tooltip :label="$t('common.column.leverage')" min-width="150">
              <template slot-scope="scope">
                <div v-if="scope.row.status !== 'expired'">
                  {{ scope.row.leverage }} : 1
                  <div class="icon" v-if="scope.row.leverage">
                    <a :class="{ restrict: checkRestrict(scope.row.group) }">
                      <img
                        class="down"
                        src="@/assets/images/menu/down.png"
                        alt
                        @click.stop.prevent="!checkRestrict(scope.row.group) && leverageDemoClick(scope.row)"
                        :data-testid="'platform' + scope.row.platform"
                      />
                    </a>
                  </div>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column show-overflow-tooltip :label="$t('common.column.status')" prop="status" min-width="110">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 'active'" class="blue">
                  {{ scope.row.status | accStatus }}
                </div>
                <div v-if="scope.row.status == 'expired'" class="red">
                  <i>{{ scope.row.status | accStatus }}</i>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column show-overflow-tooltip :label="$t('common.column.resetBal')" prop="status" min-width="150">
              <template slot-scope="scope">
                <div v-if="scope.row.status === 'active'" class="reset-balance">
                  <a
                    :class="{ restrict: checkRestrict(scope.row.group) }"
                    @click.stop.prevent="!checkRestrict(scope.row.group) && resetBalClick(scope.row)"
                    >$</a
                  >
                </div>
                <div v-if="scope.row.status === 'expired'" class="red">-</div>
              </template>
            </el-table-column> -->
            <!-- <el-table-column show-overflow-tooltip :label="$t('resetPassword.header')" min-width="150">
              <template slot-scope="scope">
                <a
                  v-if="scope.row.status === 'active'"
                  @click.stop.prevent="handleLock(scope.$index, scope.row)"
                  :data-testid="'resetPassword' + scope.row.platform"
                >
                  <img src="@/assets/images/home/lock_icon.png" alt />
                </a>
                <i v-else>-</i>
              </template>
            </el-table-column> -->
            <el-table-column>
              <template slot-scope="scope">
                <div v-if="scope.row.status == 'active'">
                  <el-dropdown>
                    <img src="@/assets/images/btn-more.png" class="button-more" />
                    <el-dropdown-menu slot="dropdown" trigger="click">
                      <el-dropdown-item v-if="scope.row.status !== 'expired'">
                        <div
                          class="inner-item"
                          @click.stop.prevent="!checkRestrict(scope.row.group) && leverageDemoClick(scope.row)"
                          :data-testid="'platform' + scope.row.platform"
                        >
                          {{ $t('common.button.changeLeverage2') }}
                        </div>
                      </el-dropdown-item>
                      <!-- <el-dropdown-item>
                      <div class="inner-item" @click="copyTrade()">{{ $t('common.button.copyTrade') }}</div>
                    </el-dropdown-item> -->
                      <!-- resetTypeDisplay: 用于判断是否显示重置按钮，有三个值，1为不显示，2为显示，3为显示但是不可点击 -->
                      <el-dropdown-item v-if="scope.row.status === 'active'">
                        <div
                          class="inner-item"
                          @click.stop.prevent="!checkRestrict(scope.row.group) && resetBalClick(scope.row)"
                        >
                          {{ $t('common.button.resetBalance') }}
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item v-if="scope.row.status === 'active'">
                        <div
                          class="inner-item"
                          @click.stop.prevent="handleLock(scope.$index, scope.row)"
                          :data-testid="'resetPassword' + scope.row.platform"
                        >
                          {{ $t('common.button.resetPassword') }}
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <template slot="components">
      <!-- CHANGE LEVERAGE 弹框开始 -->
      <ChangeLeverage
        :changeLeverageVisible.sync="changeLeverageVisible"
        :leverageConfirmVisible.sync="leverageConfirmVisible"
        :showTNC="false"
        :leverageChangeSubmitSuccess.sync="leverageChangeSubmitSuccess"
        :leverageChangeSubmitFail.sync="leverageChangeSubmitFail"
        :leverageChangeSubmitReject.sync="leverageChangeSubmitReject"
        :leverageChangeAuditSuccess.sync="leverageChangeAuditSuccess"
        :leverageChangeSubmitTimeOut.sync="leverageChangeSubmitTimeOut"
        @resetTable="getDemo"
        method="demo"
      ></ChangeLeverage>

      <!-- CHANGE LEVERAGE confirm 弹框开始 -->
      <Common :homeVisible.sync="leverageConfirmVisible">
        <div class="dialog-body" v-if="leverageChangeSubmitSuccess">
          <img src="@/assets/images/dialog_smile.png" alt />
          <p
            v-html="
              leverageChangeAuditSuccess
                ? $t('home.leverage.changeLeverageAutoComplete')
                : $t('home.leverage.changeLeverageConfirm')
            "
          ></p>
        </div>
        <div class="dialog-body" v-if="leverageChangeSubmitFail">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageFail')"></p>
        </div>
        <div class="dialog-body" v-if="leverageChangeSubmitReject">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageReject')"></p>
        </div>
        <div class="dialog-body" v-if="leverageChangeSubmitTimeOut">
          <img src="@/assets/images/dialog_false.png" alt />
          <p v-html="$t('home.leverage.changeLeverageDefault', { supportEmail: GLOBAL_CONTACT_EMAIL })"></p>
        </div>
      </Common>
      <!-- CHANGE LEVERAGE confirm 弹框结束 -->

      <!-- 点击小锁弹框开始 -->
      <Password
        :passwordVisible.sync="passwordVisible"
        :forgotPwdVisible.sync="forgotPwdVisible"
        :number="number"
        :uuid="uuid"
        @forgotPwdClick="forgotPwdClick"
      ></Password>
      <!-- 点击小锁弹框结束 -->

      <!-- 点击 CHANGE PASSWORD  表单弹框开始 -->
      <ChangePassword
        :changePasswordVisible.sync="changePasswordVisible"
        :pwdConfirmVisible.sync="pwdConfirmVisible"
        :number="number"
        :isDemo="isDemo"
        @forgotPwdClick="forgotPwdClick"
      ></ChangePassword>
      <!-- 点击 CHANGE PASSWORD  表单弹框结束 -->

      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->
      <Common :homeVisible.sync="pwdConfirmVisible">
        <img src="@/assets/images/dialog/dialog_true.png" alt />
        <p v-html="$t('home.pw.confirm')"></p>
      </Common>
      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->

      <!-- FORGOT PASSWORD 开始 -->
      <Common :homeVisible.sync="forgotPwdVisible">
        <img src="@/assets/images/dialog/dialog_true.png" alt />
        <div v-html="$t('home.pw.email')"></div>
      </Common>
      <!-- FORGOT PASSWORD 结束 -->

      <!-- 点击 RESET BALANCE 开始 -->
      <ResetBalance :resetBalVisible.sync="resetBalVisible" :number="number" @resetTable="getDemo"> </ResetBalance>
      <!-- 点击 RESET BALANCE 结束 -->
    </template>
  </Home>
</template>

<script>
import Home from '@/components/template/Home';
import ChangeLeverage from '@/components/home/ChangeLeverage';
import Common from '@/components/home/Common';
import { apiHomeGet_demo_accs, apiReq_reset_mt4_password } from '@/resource';
import changeLeverageMixins from '@/mixins/page/home/changeLeverage';
import Password from '@/components/home/Password';
import ChangePassword from '@/components/home/ChangePassword';
import ResetBalance from '@/components/home/ResetBalance';
import PropTradingDashboardTopBanner from '@/components/propTrading/components/PropTradingDashboardTopBanner.vue';

export default {
  components: { Home, ChangeLeverage, Common, Password, ChangePassword, ResetBalance, PropTradingDashboardTopBanner },
  mixins: [changeLeverageMixins],
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      allMetaDemoAccounts: [],
      passwordVisible: false,
      changePasswordVisible: false,
      pwdConfirmVisible: false,
      forgotPwdVisible: false,
      resetBalVisible: false,
      number: null,
      isDemo: true
    };
  },
  mounted() {
    this.getDemo();
  },
  computed: {
    showPropTrade() {
      return this.$store.state.propTrading.eligible;
    }
  },
  methods: {
    getDemo() {
      apiHomeGet_demo_accs().then(resp => {
        this.allMetaDemoAccounts = resp.data.data;
      });
    },
    handleLock(index, row) {
      this.passwordVisible = true;
      this.number = parseInt(row.accNum);
    },
    forgotPwdClick() {
      this.sendResetPasswordEmail().then(result => {
        let vm = this;
        if (result) {
          this.passwordVisible = false;
          this.forgotPwdVisible = true;
        } else {
          this.$message({
            message: vm.$t('home.pw.error'),
            type: 'error'
          });
        }
      });
    },
    resetBalClick(row) {
      this.resetBalVisible = true;
      this.number = parseInt(row.accNum);
    },
    sendResetPasswordEmail() {
      const baseUrl = location.protocol + '//' + location.hostname;
      return apiReq_reset_mt4_password({
        userId: this.uuid,
        mtAccount: this.number,
        baseUrl: baseUrl,
        isDemo: this.isDemo
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log('The reset password email is sent out');
            return Promise.resolve(true);
          } else {
            console.log('failed to sent out the reset password');
          }
        } else {
          console.log('Errors happened when applying for the reset password emails');
        }
        return Promise.resolve(false);
      });
    },
    checkRestrict(val) {
      if (this.$store.state.common.regulator === 'svg' && val === 'TEST_PULS_G_USD') {
        return true;
      }

      if (this.$store.state.common.regulator === 'fsa' && val === 'TEST_PUGS_G_USD') {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home.scss';
</style>
